@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap);
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	font-family: 'Montserrat', sans-serif;
}


body,
html {
	width: 100%;
	min-height: 100%;

}
body::-webkit-scrollbar {
		display: none;
	}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/*-----------------------------World map css------------------------------------- */
#tooltip {
	position: fixed;
	background: rgb(247, 247, 247);
	padding: 7px 10px;
	text-align: center;
	display: none;
   font-size: 0.8rem;
	font-weight: 600;
   border-radius: 8px;
	box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
}

.rsm-geography {
	outline: none;
	border: solid 2px black;
}
.rsm-geography:nth-child(7) {
	display: none;
}

.rsm-geography:nth-child(66) {
	display: none;
}



